import styled from "styled-components";

import ChevronDown from "@/assets/svg/chevronDown.svg";

const DropdownCollectionTriggerWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 4px;
  margin-top: 1px;
  margin-right: -10px;
`;

const DropdownCollectionLabel = styled.span<{ $darkMode: boolean }>`
  color: ${({ $darkMode }) => ($darkMode ? "#A5A6A6" : "#3f4549")};
  font-size: 14px;
  line-height: 14px;
  font-weight: 520;
  letter-spacing: -0.04px;
  font-family: var(--font-inter);
  z-index: 100;
  display: block;
  position: relative;
`;

const DropdownCollectionIcon = styled.div<{ $darkMode: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  opacity: 0.7;
  color: ${({ $darkMode }) => ($darkMode ? "#A5A6A6" : "#3f4549")};
`;

export default function DropdownCollectionTrigger({ item, refs, getReferenceProps, darkMode }) {
  return (
    <DropdownCollectionTriggerWrapper ref={refs.setReference} {...getReferenceProps()}>
      <DropdownCollectionLabel $darkMode={darkMode}>{item.label}</DropdownCollectionLabel>
      <DropdownCollectionIcon $darkMode={darkMode}>
        <ChevronDown />
      </DropdownCollectionIcon>
    </DropdownCollectionTriggerWrapper>
  );
}

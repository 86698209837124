// useScrollHide.ts
import { useEffect, useRef } from "react";
import { AnimationControls } from "motion/react";

export function useScrollHide(controls: AnimationControls, isOpen: boolean) {
  const lastScrollY = useRef(typeof window !== "undefined" ? window.scrollY : 0);
  const scrollDirection = useRef<"up" | "down" | null>(null);
  const cumulativeScroll = useRef(0);
  const isVisible = useRef(true);
  const isHandlingScroll = useRef(true);

  const SCROLL_UP_THRESHOLD = 100; // pixels needed to show the bar when scrolling up
  const SCROLL_DOWN_THRESHOLD = 100; // pixels needed to hide the bar when scrolling down

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen || !isHandlingScroll.current) {
        return;
      }

      const currentScrollY = window.scrollY;
      const deltaY = currentScrollY - lastScrollY.current;

      // Determine the new scroll direction
      let newScrollDirection: "up" | "down" | null = scrollDirection.current;
      if (deltaY > 0) {
        newScrollDirection = "down";
      } else if (deltaY < 0) {
        newScrollDirection = "up";
      }

      // If the scroll direction changed and there's movement, reset cumulative scroll
      if (newScrollDirection !== scrollDirection.current && deltaY !== 0) {
        cumulativeScroll.current = 0;
      }

      cumulativeScroll.current += Math.abs(deltaY);

      if (currentScrollY <= 100) {
        // Near the top, always show the bar
        if (!isVisible.current) {
          isVisible.current = true;
          controls.start({
            y: 0,
            transition: { duration: 0.3, ease: "easeOut" },
          });
        }
        cumulativeScroll.current = 0; // Reset cumulative scroll
      } else {
        if (newScrollDirection === "down") {
          if (isVisible.current && cumulativeScroll.current >= SCROLL_DOWN_THRESHOLD) {
            isVisible.current = false;
            controls.start({
              y: -100,
              transition: { duration: 0.3, ease: "easeOut" },
            });
            cumulativeScroll.current = 0; // Reset cumulative scroll
          }
        } else if (newScrollDirection === "up") {
          if (!isVisible.current && cumulativeScroll.current >= SCROLL_UP_THRESHOLD) {
            isVisible.current = true;
            controls.start({
              y: 0,
              transition: { duration: 0.3, ease: "easeOut" },
            });
            cumulativeScroll.current = 0; // Reset cumulative scroll
          }
        }
      }

      scrollDirection.current = newScrollDirection;
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls, isOpen]);

  useEffect(() => {
    if (isOpen) {
      // Menu is open, reset scroll tracking and pause handling scroll events
      isHandlingScroll.current = false;
      cumulativeScroll.current = 0;
    } else {
      // Menu is closed, resume handling scroll events
      isHandlingScroll.current = true;
      lastScrollY.current = window.scrollY || 0;
      scrollDirection.current = null;
      cumulativeScroll.current = 0;
    }
  }, [isOpen]);
}

import React, { useState, useRef, useEffect, useCallback } from "react";
import Link from "next/link";
import styled, { css } from "styled-components";
import Logo from "@/assets/svg/ppLogo.svg";
import { BREAKPOINT_SM } from "@/constants";
import Button from "@/components/Buttons/Button";

import debounce from "lodash/debounce";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import DropdownMenu from "@/components/Core/Navigation/Header/DropdownMenu";
import DropdownCollection from "@/components/Core/Navigation/Header/DropdownCollection";
import mq from "@/utils/mq";
import SectorsDropdownCollection from "@/components/Core/Navigation/Header/SectorsDropdownCollection";
import { useAllSbSectors } from "@/hooks/useAllSbSectors";

const HeaderContainer = styled.div<{
  $hasNestedNav: boolean;
  $transparentMode: boolean;
  $darkMode: boolean;
  $blurBackground: boolean;
}>`
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  min-height: 64px;
  transition:
    background-color 0.3s ease,
    backdrop-filter 0.3s ease;
  ${({ $hasNestedNav }) =>
    $hasNestedNav &&
    css`
      box-shadow: none;
      border-bottom: 1px solid #dfe3e6;
    `}
  ${({ $transparentMode }) =>
    $transparentMode &&
    css`
      background-color: transparent;
      position: fixed;
      backdrop-filter: blur(0px);
    `}
  ${({ $blurBackground }) =>
    $blurBackground &&
    css`
      backdrop-filter: saturate(180%) blur(20px);
      background-color: rgba(8, 11, 11, 0.8) !important;
      transition:
        background-color 0.3s ease,
        backdrop-filter 0.3s ease;
    `}
  @media (max-width: 1000px) {
    display: none;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  max-width: 92.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: ${BREAKPOINT_SM}px) {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  position: relative;
`;

const HeaderMain = styled.div``;

const Main = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 1200px) {
    position: unset;
  }
`;

const MainOptions = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  @media (max-width: 1200px) {
    position: unset;
  }
`;

const NavigationOption = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
`;

const NavigationTrigger = styled.button`
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 9999px;
  background: transparent;
  border: 0;
  font-family: var(--font-pp-neue);
  padding: 0px 12px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavigationDropdownTrigger = styled.div`
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 9999px;
  background: transparent;
  border: 0;

  padding: 0px 12px;

  cursor: pointer;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavigationLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  outline: none;
`;

const NavigationOptionLabel = styled.span<{ $darkMode: boolean }>`
  color: ${({ $darkMode }) => ($darkMode ? "#A5A6A6" : "#3f4549")};
  font-size: 14px;
  line-height: 14px;
  font-weight: 520;
  letter-spacing: -0.04px;
  font-family: var(--font-inter);
  z-index: 100;
  display: block;
  position: relative;
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
`;

const LogoLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  outline: none;
`;

const LogoContainer = styled.div`
  //height: 46px;
  width: 100px;
  color: var(--PP-Text-Primary);
  overflow: hidden;
  svg {
    height: 100%;
    width: 100%;
  }
`;

const ActiveHighlight = styled.div<{ $isVisible: boolean; $darkMode: boolean }>`
  position: absolute;
  height: 35px;
  background: hsla(0, 0%, 92%, 1);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 6px;
  pointer-events: none;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.2s ease;
  ${({ $darkMode }) =>
    $darkMode &&
    css<{ $darkMode: boolean }>`
      background: hsla(0, 0%, 100%, 0.16);
    `}
`;

export interface MenuItem {
  component: string;
  label: string;
  link: any;
}

interface HeaderProps {
  data: {
    menuItems?: MenuItem[];
    menuCtaLabel: string;
    menuCtaLink: any;
  };
  themeProperties: {
    backgroundColor: string;
  };
  hasNestedNav: boolean;
  transparentMode?: boolean;
  darkMode?: boolean;
  blurOnScroll?: boolean;
}

export default function Header({
  data,
  themeProperties,
  hasNestedNav,
  transparentMode,
  darkMode,
  blurOnScroll = false,
}: HeaderProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [blurBackground, setBlurBackground] = useState(false);
  const { data: sectors, isLoading, error } = useAllSbSectors();
  const [isVisible, setIsVisible] = useState(false);
  const optionsRef = useRef<(HTMLLIElement | null)[]>([]);
  const positionRef = useRef({ left: 0, width: 0 });
  const headerMainRef = useRef<HTMLDivElement>(null);

  const updatePosition = (index: number) => {
    const element = optionsRef.current[index];
    if (element) {
      positionRef.current = {
        left: element.offsetLeft,
        width: element.offsetWidth,
      };
    }
  };

  const debouncedUpdatePosition = useCallback(
    debounce((index: number) => {
      updatePosition(index);
    }, 50),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedUpdatePosition.cancel();
    };
  }, [debouncedUpdatePosition]);

  useEffect(() => {
    if (!blurOnScroll) return;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setBlurBackground(scrollPosition > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [blurOnScroll]);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
    setIsVisible(true);
    updatePosition(index); // Immediately update position
    debouncedUpdatePosition(index); // Schedule a debounced update
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
    setActiveIndex(null);
  };

  return (
    <HeaderContainer
      $hasNestedNav={hasNestedNav}
      style={{ backgroundColor: transparentMode ? "transparent" : themeProperties.backgroundColor }}
      $transparentMode={transparentMode}
      $darkMode={darkMode}
      $blurBackground={blurBackground}>
      <HeaderWrapper>
        <HeaderLeft>
          <LogoLink href='/'>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </LogoLink>
          <HeaderMain ref={headerMainRef}>
            <Main>
              <MainOptions>
                <ActiveHighlight
                  $isVisible={isVisible}
                  $darkMode={darkMode}
                  style={{
                    width: positionRef.current.width,
                    transform: `translateX(${positionRef.current.left}px) translateY(-50%)`,
                    transition: `opacity 0.2s ease, transform 0.2s ease ${isVisible ? "0s" : "0.2s"}, width 0.2s ease`,
                  }}
                />
                {data?.menuItems?.map((item, i) => (
                  <React.Fragment key={i}>
                    {item.component === "menuDropdownItem" ? (
                      <NavigationOption
                        ref={(el) => {
                          optionsRef.current[i] = el;
                        }}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}>
                        <NavigationDropdownTrigger>
                          <DropdownCollection item={item} headerMainRef={headerMainRef} darkMode={darkMode} />
                        </NavigationDropdownTrigger>
                      </NavigationOption>
                    ) : item.component === "menuDropdownSectorsContent" ? (
                      <NavigationOption
                        ref={(el) => {
                          optionsRef.current[i] = el;
                        }}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}>
                        <NavigationDropdownTrigger>
                          <SectorsDropdownCollection item={item} headerMainRef={headerMainRef} darkMode={darkMode} />
                        </NavigationDropdownTrigger>
                      </NavigationOption>
                    ) : (
                      <NavigationOption
                        ref={(el) => {
                          optionsRef.current[i] = el;
                        }}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}>
                        <NavigationTrigger>
                          <NavigationLink href={parseStoryblokLink(item.link)}>
                            <NavigationOptionLabel $darkMode={darkMode}>{item.label}</NavigationOptionLabel>
                          </NavigationLink>
                        </NavigationTrigger>
                      </NavigationOption>
                    )}
                  </React.Fragment>
                ))}
              </MainOptions>
            </Main>
          </HeaderMain>
        </HeaderLeft>
        <HeaderRight>
          <Button label={data?.menuCtaLabel} style='primary' enableArrow link={parseStoryblokLink(data?.menuCtaLink)} />
        </HeaderRight>
      </HeaderWrapper>
    </HeaderContainer>
  );
}

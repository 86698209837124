// @/hooks/usePreventScroll.ts

import { useEffect, useRef, useCallback, useLayoutEffect } from "react";

const SCROLL_LOCK_CLASS = "scroll-lock";

export function usePreventScroll(isOpen: boolean) {
  const scrollPosition = useRef(0);

  const lockScroll = useCallback(() => {
    scrollPosition.current = window.scrollY;
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

    document.body.classList.add(SCROLL_LOCK_CLASS);
    document.body.style.setProperty("--scroll-position", `-${scrollPosition.current}px`);
    document.body.style.setProperty("--scroll-bar-width", `${scrollBarWidth}px`);
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.classList.remove(SCROLL_LOCK_CLASS);
    document.body.style.removeProperty("--scroll-position");
    document.body.style.removeProperty("--scroll-bar-width");
    window.scrollTo(0, scrollPosition.current);
  }, []);

  useEffect(() => {
    if (isOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }

    return () => {
      if (document.body.classList.contains(SCROLL_LOCK_CLASS)) {
        unlockScroll();
      }
    };
  }, [isOpen, lockScroll, unlockScroll]);
}

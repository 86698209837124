import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "motion/react";
import MobileNavMenuPage from "@/components/Core/Navigation/MobileNavigation/MobileNavMenuPage";
import { MenuItemType, DropdownCollectionType } from "@/types/navigation";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import { useRouter } from "next/router";

const MobileNavMenuWrapper = styled(motion.div)`
  overflow: scroll;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  will-change: scroll-position, contents, transform, top, height;
`;

const MobileNavMenuContentWrapper = styled(motion.div)`
  padding-top: 1rem;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  //margin: 0 0 24px 0;
  height: 100%;
  will-change: scroll-position, contents, transform, top, height;
`;

interface MobileNavigationMenuProps {
  data: {
    menuItems: MenuItemType[];
    menuCtaLink: string;
    menuCtaLabel: string;
  };
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sectors?: any[];
  darkMode?: boolean;
}

type PageType = MenuItemType[] | DropdownCollectionType[];

export default function MobileNavigationMenu({
  data,
  isOpen,
  setIsOpen,
  sectors,
  darkMode,
}: MobileNavigationMenuProps) {
  const [pageStack, setPageStack] = useState<PageType[]>([data.menuItems]);
  const router = useRouter();

  const handleItemClick = useCallback(
    (item: MenuItemType | DropdownCollectionType) => {
      if ("component" in item && item.component === "menuDropdownItem" && item.dropdownItems) {
        setPageStack((prev) => [...prev, item.dropdownItems]);
      } else if ("component" in item && item.component === "menuDropdownSectorsContent") {
        // Fetch sectors and add them to the page stack

        if (sectors) {
          setPageStack((prev) => [...prev, sectors]);
        }
        //@ts-ignore
      } else if ("content" in item && item.content.component === "sectorPage") {
        //@ts-ignore
        router.push(item.full_slug);
        setIsOpen(false);
      } else if ("items" in item) {
        setPageStack((prev) => [...prev, item.items]);
      } else if ("link" in item && item.link) {
        if ("comingSoonMode" in item && item.comingSoonMode) {
          return null;
        }
        router.push(parseStoryblokLink(item.link as any));
        setIsOpen(false);
      }
    },
    [setIsOpen, sectors],
  );

  const handleBack = useCallback(() => {
    setPageStack((prev) => prev.slice(0, -1));
  }, []);

  const currentPage = pageStack[pageStack.length - 1];

  return (
    <MobileNavMenuWrapper>
      <MobileNavMenuContentWrapper>
        <AnimatePresence mode='wait'>
          <MobileNavMenuPage
            key={pageStack.length}
            items={currentPage}
            onItemClick={handleItemClick}
            onBack={handleBack}
            showBack={pageStack.length > 1}
            isRootPage={pageStack.length === 1}
            ctaLink={data.menuCtaLink}
            ctaLabel={data.menuCtaLabel}
            darkMode={darkMode}
          />
        </AnimatePresence>
      </MobileNavMenuContentWrapper>
    </MobileNavMenuWrapper>
  );
}

import React, { useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { motion, AnimatePresence, useAnimation } from "motion/react";
import { MenuItemType } from "@/types/navigation";
import PPLogo from "@/assets/svg/updLogoCompress.svg";
import MobileMenuIcon from "@/assets/svg/mobileMenuIcon.svg";
import MobileNavigationMenu from "@/components/Core/Navigation/MobileNavigation/MobileNavigationMenu";
import { usePreventScroll } from "@/hooks/usePreventScroll";
import { useScrollHide } from "@/hooks/useScrollHide";
import mq from "@/utils/mq";
import Link from "next/link";
import { useAllSbSectors } from "@/hooks/useAllSbSectors";

const NAV_BAR_HEIGHT = 56;
const VERTICAL_PADDING = 16; // 8px top + 8px bottom

const MobileNavigationContainer = styled(motion.nav)`
  display: none;
  user-select: none;
  pointer-events: none;
  position: fixed;
  will-change: scroll-position, contents, transform, top;
  @media (max-width: 1000px) {
    display: block;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    padding: 8px;
  }
`;

const MobileNavigationContentWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(80px) saturate(120%);
  background: #e6e6e67a;
  border-radius: 0.75rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  z-index: 1000;
  position: block;
  will-change: scroll-position, contents, transform, top, height;
`;

const MainLayer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${NAV_BAR_HEIGHT}px;
  justify-content: space-between;
  padding: 0 1.5rem;
  flex-shrink: 0;
  z-index: 1000;
  will-change: scroll-position, contents, transform, top, height;
`;

const ScrollableNavigationContent = styled(motion.div)`
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - ${NAV_BAR_HEIGHT}px - ${VERTICAL_PADDING}px);
  padding-bottom: ${VERTICAL_PADDING / 2}px;
  will-change: scroll-position, contents, transform, top, height;
`;

const LogoWrapper = styled.div<{ $darkMode?: boolean }>`
  width: 68px;
  color: ${({ $darkMode }) => ($darkMode ? "#fff" : "#182024")};
`;

const MenuIconWrapper = styled.button<{ $darkMode?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $darkMode }) => ($darkMode ? "#fff" : "#000")};
  svg {
    width: 16px;
    height: 16px;
  }
`;

interface MobileNavigationProps {
  data: {
    menuItems: MenuItemType[];
    menuCtaLink: string;
    menuCtaLabel: string;
  };
  hasNestedNav: boolean;
  darkMode?: boolean;
}

export default function MobileNavigation({ data, hasNestedNav, darkMode }: MobileNavigationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { data: sectors, isLoading, error } = useAllSbSectors();

  usePreventScroll(isOpen);

  const toggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const contentVariants = {
    closed: {
      height: NAV_BAR_HEIGHT,
      backdropFilter: darkMode ? "blur(80px) saturate(180%)" : "blur(80px) saturate(120%)",
      background: darkMode ? "#161e1ecc" : "#e6e6e6d8",
      transition: { duration: 0.5, ease: [0.77, 0, 0.175, 1] },
    },
    open: {
      height: "100%",
      backdropFilter: darkMode ? "blur(80px) saturate(100%)" : "blur(0) saturate(100%)",
      background: darkMode ? "#161e1ecc" : "#e6e6e6",
      transition: { duration: 0.7, ease: [0.77, 0, 0.175, 1] },
    },
  };

  // Initialize controls here and pass them to the hook
  const controls = useAnimation();
  useScrollHide(controls, isOpen);

  return (
    <MobileNavigationContainer
      role='navigation'
      aria-label='Mobile navigation'
      initial={{ y: 0 }}
      animate={controls}
      transition={{ ease: [0.785, 0.135, 0.15, 0.86] }}>
      <MobileNavigationContentWrapper
        initial='closed'
        animate={isOpen ? "open" : "closed"}
        variants={contentVariants}
        transition={{ duration: 0.3 }}>
        <MainLayer>
          <Link href='/' style={{ textDecoration: "none" }}>
            <LogoWrapper $darkMode={darkMode}>
              <PPLogo />
            </LogoWrapper>
          </Link>
          <MenuIconWrapper $darkMode={darkMode} onClick={toggleMenu} aria-label='Toggle menu' aria-expanded={isOpen}>
            <MobileMenuIcon />
          </MenuIconWrapper>
        </MainLayer>
        <AnimatePresence>
          {isOpen && (
            <ScrollableNavigationContent initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <MobileNavigationMenu
                data={data}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                sectors={sectors}
                darkMode={darkMode}
              />
            </ScrollableNavigationContent>
          )}
        </AnimatePresence>
      </MobileNavigationContentWrapper>
    </MobileNavigationContainer>
  );
}

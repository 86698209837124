import NestedNavBreadcrumbs from "@/components/Core/Navigation/NestedNavigation/NestedNavBreadcrumbs";
import { BREAKPOINT_SM } from "@/constants";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";

const NestedNavContainer = styled.div`
  position: sticky;
  top: 64px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  min-height: 74px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  display: none;
  ${mq.mobile(css`
    display: none;
  `)};
`;

const NestedNavWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  max-width: 1208px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: ${BREAKPOINT_SM}px) {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
`;

const NestedNavLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
`;

const NestedNavRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
`;

export default function NestedNavigation({ data, themeProperties }) {
  return (
    <NestedNavContainer style={{ backgroundColor: themeProperties.backgroundColor }}>
      <NestedNavWrapper>
        <NestedNavLeft>
          {data?.leftContent &&
            data?.leftContent?.length > 0 &&
            data?.leftContent?.map((item, i) => <NavigationSectionRenderer key={i} blok={item} />)}
        </NestedNavLeft>
        <NestedNavRight></NestedNavRight>
      </NestedNavWrapper>
    </NestedNavContainer>
  );
}

const NavigationSectionRenderer = ({ blok }) => {
  if (blok.component === "nestedNavigationBreadcrumbs") {
    return <NestedNavBreadcrumbs data={blok} />;
  }
};

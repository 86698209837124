import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import styled, { createGlobalStyle, css } from "styled-components";
import { ReactNode, useMemo } from "react";
import { GoogleAnalytics } from "@next/third-parties/google";
import { Analytics } from "@vercel/analytics/react";
import Head from "next/head";

import Footer from "@/components/Core/Navigation/Footer";
import { SITE_DESCRIPTION, SITE_TITLE } from "@/constants";
import { seoOverrides } from "@/constants/seoOverrides";
import { themeProvider, ThemeOption, ThemeProperties } from "@/utils/ThemeProvider";
import Header from "@/components/Core/Navigation/Header";
import NestedNavigation from "@/components/Core/Navigation/NestedNavigation";
import MobileNavigation from "@/components/Core/Navigation/MobileNavigation";
import OrganisationJsonLd from "@/components/Shared/JsonLD/OrganisationJsonLd";
import VideoLibraryNavigation from "@/components/VideoLibrary/core/VideoLibraryNavigation";

const GlobalStyle = createGlobalStyle<{ $theme: ThemeProperties; $darkMode: boolean }>`
  ${(props) =>
    props.$theme &&
    css<{ $theme: ThemeProperties; $darkMode: boolean }>`
      html,
      body {
        background-color: ${(props) => (props.$darkMode ? "#08090A" : props.$theme.backgroundColor)} !important;
      }
    `}
`;

const CoreLayoutContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100vw;
`;

const ContentContainer = styled.div`
  min-height: calc(100vh - 400px);
  position: relative;
`;

const LayoutWrapper = styled.div<{ $theme: ThemeProperties }>`
  background-color: ${(props) => props.$theme.backgroundColor};
  color: ${(props) => props.$theme.textColor};
  min-height: 100vh;
`;

function VideoLibraryLayout({
  children,
  pageTitle,
  pageDescription,
  pageImage,
  blok,
  navData,
  overlappingMenu,
  footerData,
  theme = "lightGray",
  siteSettings,
  videoLibrary,
}: {
  children: ReactNode;
  pageTitle: string;
  pageDescription?: string;
  pageImage?: string;
  navData: any;
  footerData: any;
  overlappingMenu?: boolean;
  blok?: any;
  theme?: ThemeOption;
  siteSettings: any;
  videoLibrary: any;
}) {
  const router = useRouter();
  const themeProperties = useMemo(() => themeProvider(theme), [theme]);
  // Dark mode background color
  const darkModeColor = "#08090A";

  return (
    <>
      <Head>
        <meta name='theme-color' content={darkModeColor} />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
      </Head>
      <Analytics />
      <GlobalStyle $theme={themeProperties} $darkMode={true} />
      <LayoutWrapper $theme={themeProperties} style={{ backgroundColor: darkModeColor }}>
        <CoreLayoutContainer id='core-layout-container'>
          {siteSettings && (
            <OrganisationJsonLd
              name={siteSettings.organisationName}
              alternateName={siteSettings.organisationAlternateName}
              url={siteSettings.url}
              logo={siteSettings.logo.filename}
              sameAs={siteSettings.sameAs?.map((item) => item.link)}
            />
          )}
          {!seoOverrides.some((item) => router.asPath.split("/")[1].includes(item)) && (
            <NextSeo
              title={`${pageTitle} - ${SITE_TITLE}`}
              description={pageDescription ? pageDescription : SITE_DESCRIPTION}
              nofollow={process.env.NEXT_PUBLIC_PRODUCTION_SITE ? false : true}
              noindex={process.env.NEXT_PUBLIC_PRODUCTION_SITE ? false : true}
              canonical={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}${router.asPath}`}
              openGraph={{
                title: `${pageTitle} - ${SITE_TITLE}`,
                description: pageDescription ? pageDescription : SITE_DESCRIPTION,
                url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}${router.asPath}`,
                locale: "en_GB",
                type: "website",
                images: [
                  {
                    url: pageImage
                      ? pageImage
                      : `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/assets/images/sharingImage.png`,
                    width: 800,
                    height: 600,
                    alt: SITE_TITLE,
                  },
                ],
                site_name: SITE_TITLE,
              }}
            />
          )}
          <Header
            data={navData}
            hasNestedNav={blok?.nestedNavigation && blok?.nestedNavigation.length > 0}
            themeProperties={themeProperties}
            transparentMode={true}
            darkMode
            blurOnScroll={true}
          />
          <MobileNavigation
            data={navData}
            hasNestedNav={blok?.nestedNavigation && blok?.nestedNavigation.length > 0}
            darkMode
          />
          {blok?.nestedNavigation && blok?.nestedNavigation.length > 0 && (
            <NestedNavigation data={blok.nestedNavigation[0]} themeProperties={themeProperties} />
          )}
          <ContentContainer style={{ paddingTop: overlappingMenu ? "0" : "0px" }}>
            {children}
            <VideoLibraryNavigation videoLibrary={videoLibrary} />
          </ContentContainer>
          <Footer data={footerData} darkMode={true} />
        </CoreLayoutContainer>
      </LayoutWrapper>
    </>
  );
}

export default VideoLibraryLayout;

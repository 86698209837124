import styled from "styled-components";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import ChevronRight from "@/assets/svg/chevronRight.svg";
import IconRenderer from "@/components/Shared/IconRenderer";
import Link from "next/link";

const BreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: fit-content;

  gap: 10px;
`;

const BreadcrumbItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 3px;
  align-items: center;
  color: #677076;
  transition: all 0.2 (--ease-out-expo);
  &:hover {
    color: var(--PP-Text-Primary);
  }
`;

const BreadcrumbLabel = styled.div`
  color: var(--PP-Text-Primary);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 22px;
`;

const BreadcrumbLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 22px;
`;

const ChevronContainer = styled.div`
  width: 12px;
  height: 12px;
  color: inherit;
`;

const BreadcrumbIconContainer = styled.div`
  width: 12px;
  height: 12px;
  color: inherit;
`;

const BreadcrumbImageIconContainer = styled.div`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--PP-Text-Primary);
  margin-right: 2.5px;
`;

export default function NestedNavBreadcrumbs({ data }) {
  return (
    <BreadcrumbsContainer>
      {data &&
        data?.breadcrumbs?.map((breadcrumb, i) => {
          if (i === data?.breadcrumbs.length - 1) {
            return <ActiveBreadcrumb key={i} breadcrumb={breadcrumb} />;
          } else {
            return <NestedBreadcrumb key={i} breadcrumb={breadcrumb} />;
          }
        })}
    </BreadcrumbsContainer>
  );
}

const NestedBreadcrumb = ({ breadcrumb }) => {
  return (
    <BreadcrumbItemContainer>
      {breadcrumb?.icon && breadcrumb?.icon?.length > 0 && (
        <BreadcrumbImageIconContainer>
          <IconRenderer icon={breadcrumb.icon} />
        </BreadcrumbImageIconContainer>
      )}

      <BreadcrumbLink href={parseStoryblokLink(breadcrumb.link)}>{breadcrumb.label}</BreadcrumbLink>
      <ChevronContainer>
        <ChevronRight />
      </ChevronContainer>
    </BreadcrumbItemContainer>
  );
};

const ActiveBreadcrumb = ({ breadcrumb }) => {
  return (
    <BreadcrumbItemContainer>
      {breadcrumb?.icon && breadcrumb?.icon?.length > 0 && (
        <BreadcrumbImageIconContainer>
          <IconRenderer icon={breadcrumb.icon} />
        </BreadcrumbImageIconContainer>
      )}
      <BreadcrumbLabel>{breadcrumb.label}</BreadcrumbLabel>
    </BreadcrumbItemContainer>
  );
};

import { useState } from "react";
import styled from "styled-components";
import { useFloating, useHover, useInteractions, safePolygon, offset } from "@floating-ui/react";

import DropdownCollectionContent from "@/components/Core/Navigation/Header/DropdownCollection/DropdownCollectionContent";
import DropdownCollectionTrigger from "@/components/Core/Navigation/Header/DropdownCollection/DropdownCollectionTrigger";

const DropdownCollectionContainer = styled.div`
  height: 100%;
  z-index: 1000000;
  display: block;
`;

export default function DropdownCollection({ item, headerMainRef, darkMode }) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset({ mainAxis: 16, alignmentAxis: 0 })],
    transform: false,
  });

  const hover = useHover(context, {
    handleClose: safePolygon(),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <DropdownCollectionContainer>
      <DropdownCollectionTrigger refs={refs} getReferenceProps={getReferenceProps} item={item} darkMode={darkMode} />
      <DropdownCollectionContent
        refs={refs}
        floatingStyles={floatingStyles}
        getFloatingProps={getFloatingProps}
        isOpen={isOpen}
        item={item}
        setIsOpen={setIsOpen}
        darkMode={darkMode}
      />
    </DropdownCollectionContainer>
  );
}

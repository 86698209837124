import Head from "next/head";
import React from "react";

interface OrganisationJsonLdProps {
  name: string;
  alternateName?: string;
  url: string;
  logo: string;
  sameAs?: string[];
}

const OrganisationJsonLd: React.FC<OrganisationJsonLdProps> = ({ name, alternateName, url, logo, sameAs }) => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name,
    url,
    logo,
    ...(alternateName && { alternateName }),
    ...(sameAs && { sameAs }),
  };

  return (
    <Head>
      <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
    </Head>
  );
};

export default OrganisationJsonLd;

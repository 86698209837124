import LabelCta from "@/components/Buttons/LabelCta";
import DropdownCollectionItemGroup, {
  DropdownCollectionItem,
  DropdownCollectionItemGroupItems,
  DropdownCollectionItemGroupLabel,
  DropdownCollectionItemGroupWrapper,
} from "@/components/Core/Navigation/Header/DropdownCollection/DropdownCollectionItemGroup";
import SectorsDropdownCollectionItemGroup from "@/components/Core/Navigation/Header/SectorsDropdownCollection/SectorsDropdownCollectionItemGroup";
import { EASE_OUT_CUBIC_FRAMER } from "@/constants";
import { useAllSbSectors } from "@/hooks/useAllSbSectors";
import { SectorStory } from "@/types/sectors";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import { motion, AnimatePresence } from "motion/react";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import styled, { css } from "styled-components";

const SectorsDropdownCollectionWrapper = styled(motion.div)<{ $isOpen: boolean }>`
  pointer-events: ${({ $isOpen }) => ($isOpen ? "auto" : "none")};
  user-select: ${({ $isOpen }) => ($isOpen ? "auto" : "none")};
  z-index: 1000000;
  @media (max-width: 1200px) {
    margin-top: 16px;
  }
`;

const SectorsDropdownCollectionContentWrapper = styled(motion.div)<{ $darkMode: boolean }>`
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: var(--neutral-0, #fff);
  padding: 22px 0px;
  z-index: 1000000;
  ${({ $darkMode }) =>
    $darkMode &&
    css<{ $darkMode: boolean }>`
      background: #171819;
      border: 1px solid #222324;
    `}
`;

const SectorsDropdownCollectionInnerWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 320px));
  grid-row-gap: 32px;
  z-index: 1000000;
  grid-column-gap: 16px;

  width: fit-content;
  max-width: calc(1208px - 88px - 68px);
  padding: 0 8px;
  overflow: hidden;
  @media (max-width: 1300px) {
    width: fit-content;
    max-width: calc(100vw - 88px - 88px);
  }
  @media (max-width: 1200px) {
    width: fit-content;
    max-width: calc(100vw - 80px);
  }
`;

const AdditionalCtaWrapper = styled(motion.div)<{ $darkMode: boolean }>`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding: 24px 12px 0px 12px;
  ${({ $darkMode }) =>
    $darkMode &&
    css<{ $darkMode: boolean }>`
      border-top: 1px solid #222325;
    `}
`;

const wrapperAnim = {
  hidden: {
    opacity: 0,
    scale: 0.97,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.03,
      delayChildren: 0.1,
    },
  },
};

const itemAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.26, 1, 0.5, 1],
      duration: 0.5,
    },
  },
};

const splitIntoThreeGroups = (items: Array<SectorStory>): Array<Array<SectorStory>> => {
  const total = items.length;
  const groupSize = Math.floor(total / 3);
  const remainder = total % 3;

  const group1Size = groupSize + (remainder >= 1 ? 1 : 0);
  const group2Size = groupSize + (remainder === 2 ? 1 : 0);
  const group3Size = groupSize;

  const group1 = items.slice(0, group1Size);
  const group2 = items.slice(group1Size, group1Size + group2Size);
  const group3 = items.slice(group1Size + group2Size);

  return [group1, group2, group3];
};

export default function SectorsDropdownCollectionContent({
  item,
  refs,
  floatingStyles,
  getFloatingProps,
  isOpen,
  setIsOpen,
  darkMode,
}) {
  const { data: sectors, isLoading, error } = useAllSbSectors();
  const router = useRouter();

  const groups = useMemo(() => {
    if (!sectors) return [];

    return splitIntoThreeGroups(sectors);
  }, [sectors]);

  return (
    <SectorsDropdownCollectionWrapper
      ref={refs.setFloating}
      aria-hidden={!isOpen}
      $isOpen={isOpen}
      style={{ ...floatingStyles, left: 0 }}
      {...getFloatingProps()}>
      <AnimatePresence>
        {isOpen && (
          <SectorsDropdownCollectionContentWrapper
            initial='hidden'
            animate='visible'
            exit='hidden'
            variants={wrapperAnim}
            $darkMode={darkMode}>
            <SectorsDropdownCollectionInnerWrapper>
              {groups?.map((group, groupIndex) => (
                <DropdownCollectionItemGroupWrapper variants={itemAnim} key={groupIndex}>
                  <DropdownCollectionItemGroupLabel>
                    {groupIndex === 0 ? item.menuContentTitle : ""}
                  </DropdownCollectionItemGroupLabel>
                  <DropdownCollectionItemGroupItems>
                    <div key={groupIndex}>
                      {group?.map((item, i) => (
                        <DropdownCollectionItem
                          key={i}
                          item={{
                            label: item.content.menuTitle ? item.content.menuTitle : `${item.name}*`,
                            description: item.content.menuDescription
                              ? item.content.menuDescription
                              : "Missing Description",
                            raw_link: item.full_slug,
                            icon: item.content.icon,
                          }}
                          setIsOpen={setIsOpen}
                          darkMode={darkMode}
                        />
                      ))}
                    </div>
                  </DropdownCollectionItemGroupItems>
                </DropdownCollectionItemGroupWrapper>
              ))}
            </SectorsDropdownCollectionInnerWrapper>
            <AdditionalCtaWrapper variants={itemAnim} $darkMode={darkMode}>
              <LabelCta
                label={item.additionalCtaLabel}
                action={() => {
                  router.push(parseStoryblokLink(item.additionalCtaLink));
                  setIsOpen(false);
                }}
                color={darkMode ? "#cccccc" : "#6d747a"}
                colorOverride={darkMode}
                includeArrow
              />
            </AdditionalCtaWrapper>
          </SectorsDropdownCollectionContentWrapper>
        )}
      </AnimatePresence>
    </SectorsDropdownCollectionWrapper>
  );
}

import React, { useMemo } from "react";
import styled from "styled-components";
import { motion } from "motion/react";
import MobileNavMenuItem from "@/components/Core/Navigation/MobileNavigation/MobileNavMenuItem";
import { MenuItemType, DropdownCollectionType, MobileNavMenuPageProps } from "@/types/navigation";
import MenuChevron from "@/assets/svg/menuChevron.svg";
import parseStoryblokLink from "@/utils/parseStoryblokLink";

const PageWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  will-change: scroll-position, contents, transform, top, height;
`;

const MenuItemsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  will-change: scroll-position, contents, transform, top, height;
`;

const CTAButton = styled(motion.a)`
  display: block;
  width: calc(100% - 32px);
  padding: 16px 32px;
  background-color: var(--PrimaryAccent);
  border: 1px solid var(--PrimaryAccent);
  margin: 0 16px;
  border-radius: 8px;
  color: var(--Light100);
  text-align: left;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 26px;
  font-weight: 500;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  will-change: scroll-position, contents, transform, top, height;
`;

const CtaButtonLabel = styled.div`
  will-change: scroll-position, contents, transform, top, height;
`;

const CtaButtonIcon = styled.div`
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  color: var(--Light100);
  will-change: scroll-position, contents, transform, top, height;
`;

interface ExtendedMobileNavMenuPageProps extends MobileNavMenuPageProps {
  showBack: boolean;
  isRootPage: boolean;
  ctaLink?: string;
  ctaLabel?: string;
  darkMode?: boolean;
}

const containerVariants = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.02,
      duration: 0.1,
      when: "afterChildren",
    },
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.02,
      duration: 0,
      delay: 0,
      when: "beforeChildren",
    },
  },
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 100,
      duration: 0.1,
    },
  },
};

const MobileNavMenuPage: React.FC<ExtendedMobileNavMenuPageProps> = ({
  items,
  onItemClick,
  onBack,
  showBack,
  isRootPage,
  ctaLink,
  ctaLabel,
  darkMode,
}) => {
  const handleCTAClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (ctaLink) {
      onItemClick({ link: ctaLink } as any);
    }
  };

  const memoizedItems = useMemo(() => {
    return items;
  }, [items]);

  const backItem: MenuItemType = {
    _uid: "back-button",
    component: "menuItem",
    label: "Back",
    link: { cached_url: "#" },
  };

  return (
    <PageWrapper initial='hidden' animate='visible' exit='hidden' variants={containerVariants}>
      <MenuItemsWrapper>
        {showBack && (
          <MobileNavMenuItem
            itemVariants={itemVariants}
            item={backItem}
            onClick={onBack}
            isBackButton
            isSecondary
            darkMode={darkMode}
          />
        )}
        {memoizedItems?.length === 0 ? (
          <div>No menu items available</div>
        ) : (
          memoizedItems?.map((item) => (
            <MobileNavMenuItem
              itemVariants={itemVariants}
              key={item._uid}
              item={item}
              onClick={onItemClick}
              darkMode={darkMode}
            />
          ))
        )}
      </MenuItemsWrapper>
      {isRootPage && ctaLink && ctaLabel && (
        <motion.div variants={itemVariants}>
          <CTAButton href={ctaLink} onClick={handleCTAClick} whileTap={{ scale: 0.95 }}>
            <CtaButtonLabel>{ctaLabel}</CtaButtonLabel>
            <CtaButtonIcon>
              <MenuChevron />
            </CtaButtonIcon>
          </CTAButton>
        </motion.div>
      )}
    </PageWrapper>
  );
};

export default MobileNavMenuPage;

import React from "react";
import styled, { css } from "styled-components";
import { motion } from "motion/react";
import { MenuItemType, DropdownCollectionType, SectorPageType } from "@/types/navigation";
import MenuChevron from "@/assets/svg/menuChevron.svg";
import IconRenderer from "@/components/Shared/IconRenderer";

const StyledMenuItem = styled(motion.button)<{
  $isSecondary?: boolean;
  $hasDescription?: boolean;
  $darkMode?: boolean;
}>`
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
  ${(props) =>
    props.$hasDescription &&
    css`
      padding: 0.4rem 1rem;
    `}
  ${(props) =>
    props.$isSecondary &&
    css`
      padding: 0.4rem 1rem 0.2rem 1rem;
    `};
`;

const ItemContent = styled.div<{ $isSecondary?: boolean; $darkMode?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.$isSecondary ? "4px" : "8px")};
  flex-grow: 1;
`;

const ItemWithIconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-grow: 1;
`;

const ItemText = styled.span<{ $isSecondary?: boolean; $comingSoon?: boolean; $darkMode?: boolean }>`
  font-size: ${(props) => (props.$isSecondary ? "14px" : "18px")};
  letter-spacing: 0.01em;
  line-height: 100%;
  font-weight: 400;
  color: ${(props) => (props.$isSecondary ? "#5A6166" : "#182024")};
  ${(props) =>
    props.$darkMode &&
    css<{ $isSecondary?: boolean }>`
      color: ${(props) => (props.$isSecondary ? "#dcdcdc" : "#fff")};
    `}
  ${(props) =>
    props.$comingSoon &&
    css`
      color: #646464;
    `}
`;

const ItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const ItemTitle = styled.span<{ $isSecondary?: boolean; $comingSoon?: boolean; $darkMode?: boolean }>`
  font-size: 12px;
  color: #171717;
  font-weight: 500;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: ${(props) => (props.$isSecondary ? "#5A6166" : "#182024")};
  ${(props) =>
    props.$darkMode &&
    css<{ $isSecondary?: boolean }>`
      color: ${(props) => (props.$isSecondary ? "#dcdcdc" : "#fff")};
    `}
  ${(props) =>
    props.$comingSoon &&
    css<{ $darkMode?: boolean }>`
      color: ${(props) => (props.$darkMode ? "#b7b7b7" : "#646464")};
    `}
`;

const ItemDescription = styled.span<{ $isSecondary?: boolean; $comingSoon?: boolean; $darkMode?: boolean }>`
  font-size: 11px;
  color: #666;
  letter-spacing: 0px;
  line-height: 100%;
  font-weight: 500;
  text-decoration: pretty;
  ${(props) =>
    props.$comingSoon &&
    css`
      color: #9e9e9e;
    `}
  ${(props) =>
    props.$darkMode &&
    css<{ $isSecondary?: boolean }>`
      color: ${(props) => (props.$isSecondary ? "#bdbdbd" : "#9fa4ac")};
    `}
`;

const ItemIconContainer = styled.div<{ $comingSoon?: boolean; $darkMode?: boolean }>`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dbdada;
  border-radius: 4px;
  margin-right: 4px;
  flex-shrink: 0;
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      background-color: #2c2c2c;
    `}
`;

const ChevronWrapper = styled.span<{ $isBack?: boolean; $isSecondary?: boolean; $darkMode?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  transform: ${(props) => (props.$isBack ? "rotate(90deg)" : "rotate(-90deg)")};
  opacity: 0.8;
  color: ${(props) => (props.$isSecondary ? "#5A6166" : "#182024")};
  ${({ $darkMode }) =>
    $darkMode &&
    css<{ $isBack?: boolean; $isSecondary?: boolean }>`
      color: ${(props) => (props.$isSecondary ? "#dcdcdc" : "#fff")};
    `}

  flex-shrink: 0;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ComingSoonBadge = styled.div`
  background-color: var(--PrimaryAccent);
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 9px;
  line-height: 100%;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-family: var(--font-inter);
  white-space: nowrap;
`;

interface MobileNavMenuItemProps {
  item: any;
  onClick: (item: MenuItemType | DropdownCollectionType) => void;
  isBackButton?: boolean;
  itemVariants: any;
  isSecondary?: boolean;
  darkMode?: boolean;
}

const MobileNavMenuItem: React.FC<MobileNavMenuItemProps> = ({
  item,
  onClick,
  itemVariants,
  isBackButton = false,
  isSecondary = false,
  darkMode,
}) => {
  const hasSubMenu =
    ("component" in item &&
      (item.component === "menuDropdownItem" || item.component === "menuDropdownSectorsContent")) ||
    "items" in item;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
      onClick(item);
    }
  };

  if ("content" in item && item.content?.component === "sectorPage") {
    return (
      <SectorMenuItem
        item={item}
        onClick={onClick}
        itemVariants={itemVariants}
        isSecondary={isSecondary}
        handleKeyDown={handleKeyDown}
        darkMode={darkMode}
      />
    );
  }

  return (
    <StyledMenuItem
      onClick={() => onClick(item)}
      onKeyDown={handleKeyDown}
      variants={itemVariants}
      tabIndex={0}
      role='menuitem'
      aria-haspopup={hasSubMenu ? "true" : "false"}
      $isSecondary={isSecondary}
      $hasDescription={item?.description ? true : false}>
      <ItemContent $isSecondary={isSecondary}>
        {isBackButton && (
          <ChevronWrapper $isBack $isSecondary={isSecondary} $darkMode={darkMode}>
            <MenuChevron />
          </ChevronWrapper>
        )}
        {isBackButton ? (
          <ItemTextContainer>
            <ItemText $comingSoon={item.comingSoonMode} $isSecondary={true} $darkMode={darkMode}>
              {item.label}
            </ItemText>
          </ItemTextContainer>
        ) : (
          <ItemWithIconContainer>
            <ItemIconContainer $comingSoon={item.comingSoonMode} $darkMode={darkMode}>
              <IconRenderer icon={item?.icon} color={item.comingSoonMode ? "#808080" : darkMode ? "#fff" : "#171717"} />
            </ItemIconContainer>

            <ItemTextContainer>
              <ItemTitle $comingSoon={item.comingSoonMode} $isSecondary={isSecondary} $darkMode={darkMode}>
                {item.label}
              </ItemTitle>
              <ItemDescription $comingSoon={item.comingSoonMode} $darkMode={darkMode}>
                {item.description}
              </ItemDescription>
            </ItemTextContainer>
          </ItemWithIconContainer>
        )}
      </ItemContent>
      {item.comingSoonMode && <ComingSoonBadge>Coming Soon</ComingSoonBadge>}
      {!isBackButton && hasSubMenu && (
        <ChevronWrapper $darkMode={darkMode}>
          <MenuChevron />
        </ChevronWrapper>
      )}
    </StyledMenuItem>
  );
};

const SectorMenuItem = ({ item, onClick, itemVariants, isSecondary, handleKeyDown, darkMode }) => {
  return (
    <StyledMenuItem
      onClick={() => onClick(item)}
      onKeyDown={handleKeyDown}
      variants={itemVariants}
      tabIndex={0}
      role='menuitem'
      aria-haspopup={"false"}
      $isSecondary={isSecondary}
      $hasDescription={item?.content.menuDescription ? true : false}
      $darkMode={darkMode}>
      <ItemContent $isSecondary={isSecondary} $darkMode={darkMode}>
        <ItemWithIconContainer>
          <ItemIconContainer $darkMode={darkMode}>
            <IconRenderer icon={item.content.icon} color={darkMode ? "#fff" : "#5D5B5B"} />
          </ItemIconContainer>

          <ItemTextContainer>
            <ItemTitle $isSecondary={isSecondary} $darkMode={darkMode}>
              {item.content.menuTitle || item.name}
            </ItemTitle>
            <ItemDescription $darkMode={darkMode}>
              {item.content.menuDescription ? item.content.menuDescription : "Missing Description"}
            </ItemDescription>
          </ItemTextContainer>
        </ItemWithIconContainer>
      </ItemContent>
    </StyledMenuItem>
  );
};

export default React.memo(MobileNavMenuItem);

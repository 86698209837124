import styled, { css } from "styled-components";

import ContentWrapper from "@/components/Core/ContentWrapper";
import Link from "next/link";
import FooterSection, { FooterSectionTitle } from "@/components/Core/Navigation/Footer/FooterSection";
import Logo from "@/assets/svg/ppLogo.svg";
import { BREAKPOINT_SM } from "@/constants";
import EmailSubscribeBox from "@/components/FormFields/emailSubscribeBox";
import mq from "@/utils/mq";

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  gap: 1rem;
  padding-top: 56px;
  max-width: 1440px;
  width: 100%;
  padding-bottom: 56px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  ${mq.mobile(css`
    flex-direction: column;
  `)};
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 13%;
`;

const FooterSections = styled.div`
  grid-row-gap: 1rem;
  display: grid;
  flex-basis: 87%;
  ${mq.mobile(css`
    max-width: 500px;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  `)};
  @media (max-width: 475px) {
    max-width: 350px;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  @media (max-width: 330px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
`;

const FooterNewsletter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  @media (min-width: ${BREAKPOINT_SM}px) {
    width: 240px;
  }
`;

const FooterSectionBody = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #9ca3af;
`;

const LogoWrapper = styled.div`
  width: 105px;
`;

const ConsentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
`;

const ConsentLink = styled.a<{ $darkMode: boolean }>`
  color: #52525b;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  line-height: 21px;
  white-space: nowrap;
  padding-bottom: 24px;
  opacity: 0.7;
  ${({ $darkMode }) =>
    $darkMode &&
    css<{ $darkMode: boolean }>`
      color: #a5a6a6;
    `}
`;

const Subtext = styled.div<{ $darkMode: boolean }>`
  color: #52525b;
  font-size: 11px;
  cursor: pointer;
  text-decoration: none;
  line-height: 18px;

  padding-bottom: 24px;
  opacity: 0.7;
  ${({ $darkMode }) =>
    $darkMode &&
    css<{ $darkMode: boolean }>`
      color: #a5a6a6;
    `}
  p {
    margin: 0;
  }
`;

const Footer = ({ data, darkMode }: { data: any; darkMode?: boolean }) => {
  return (
    <ContentWrapper backgroundColor={darkMode ? "#08090A" : "#F9FAFB"}>
      <FooterWrapper>
        <FooterLeft>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </FooterLeft>
        <FooterSections style={{ gridTemplateColumns: `repeat(${data?.footerSections?.length}, minmax(0, 1fr))` }}>
          {data?.footerSections?.map((section, i) => <FooterSection key={i} section={section} darkMode={darkMode} />)}
        </FooterSections>

        {/*<FooterNewsletter>
          <FooterSectionTitle>Newsletter</FooterSectionTitle>
          <EmailSubscribeBox />
          <FooterSectionBody>
            Get the latest Plant Plan news and updates. You can unsubscribe at any time.
          </FooterSectionBody>
        </FooterNewsletter>*/}
      </FooterWrapper>
      <ConsentWrapper>
        <Subtext $darkMode={darkMode}>
          <p>Plant Plan Limited</p>
          <p>Quartz House, Quartz Close, Enderby, Leicester, LE19 4SG</p>
          <p>Company Number: 07573415</p>
          <p>Registered in England and Wales</p>
        </Subtext>
        <ConsentLink href='#' className='termly-display-preferences' $darkMode={darkMode}>
          Cookie Consent Preferences
        </ConsentLink>
      </ConsentWrapper>
    </ContentWrapper>
  );
};

export default Footer;

import DropdownCollectionItemGroup from "@/components/Core/Navigation/Header/DropdownCollection/DropdownCollectionItemGroup";
import { EASE_OUT_CUBIC_FRAMER } from "@/constants";
import { motion, AnimatePresence } from "motion/react";
import styled, { css } from "styled-components";

const DropdownCollectionWrapper = styled(motion.div)<{ $isOpen: boolean }>`
  pointer-events: ${({ $isOpen }) => ($isOpen ? "auto" : "none")};
  user-select: ${({ $isOpen }) => ($isOpen ? "auto" : "none")};
  z-index: 1000000;
  @media (max-width: 1200px) {
    margin-top: 16px;
  }
`;

const DropdownCollectionInnerWrapper = styled(motion.div)<{ $darkMode: boolean }>`
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: var(--neutral-0, #fff);
  padding: 22px 8px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 320px));
  grid-row-gap: 32px;
  z-index: 1000000;
  grid-column-gap: 16px;
  width: fit-content;
  max-width: calc(1208px - 88px - 68px);
  flex-wrap: wrap;
  overflow: hidden;
  ${({ $darkMode }) =>
    $darkMode &&
    css<{ $darkMode: boolean }>`
      background: #171819;
      border: 1px solid #222324;
    `}
  @media (max-width: 1300px) {
    width: fit-content;
    max-width: calc(100vw - 88px - 88px);
  }
  @media (max-width: 1200px) {
    width: fit-content;
    max-width: calc(100vw - 80px);
  }
`;

const wrapperAnim = {
  hidden: {
    opacity: 0,
    scale: 0.97,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.03,
      delayChildren: 0.1,
    },
  },
};

const itemAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.26, 1, 0.5, 1],
      duration: 0.5,
    },
  },
};

export default function DropdownCollectionContent({
  item,
  refs,
  floatingStyles,
  getFloatingProps,
  isOpen,
  setIsOpen,
  darkMode,
}) {
  return (
    <DropdownCollectionWrapper
      ref={refs.setFloating}
      aria-hidden={!isOpen}
      $isOpen={isOpen}
      style={{ ...floatingStyles, left: 0 }}
      {...getFloatingProps()}>
      <AnimatePresence>
        {isOpen && (
          <DropdownCollectionInnerWrapper
            initial='hidden'
            animate='visible'
            exit='hidden'
            variants={wrapperAnim}
            $darkMode={darkMode}>
            {item.dropdownItems?.map((item, i) => (
              <motion.div key={i} variants={itemAnim}>
                <DropdownCollectionItemGroup
                  label={item.label}
                  items={item.items}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  darkMode={darkMode}
                />
              </motion.div>
            ))}
          </DropdownCollectionInnerWrapper>
        )}
      </AnimatePresence>
    </DropdownCollectionWrapper>
  );
}

import styled, { css } from "styled-components";

import Link from "next/link";
import parseStoryblokLink from "@/utils/parseStoryblokLink";

import ChevronDown from "@/assets/svg/chevronDown.svg";

const FooterSectionWrapper = styled.div`
  //min-width: min(180px, 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  @media (max-width: 1000px) {
    min-width: 50%;
    margin-top: 20px;
    flex: 1 1;
  }
  @media (max-width: 420px) {
    min-width: 100%;
  }
`;

export const FooterSectionTitle = styled.h3<{ $darkMode: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ $darkMode }) => ($darkMode ? "#fff" : "#000000")};
`;

const FooterSectionList = styled.ul`
  padding: 0;
`;

const FooterSectionItem = styled.li`
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 0;
  margin-top: 0;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

const FooterSectionLink = styled(Link)<{ $accented?: boolean; $darkMode: boolean }>`
  color: ${({ $darkMode }) => ($darkMode ? "#A5A6A6" : "#52525b")};
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
  line-height: 21px;
  white-space: nowrap;
  ${(props) =>
    props.$accented &&
    css<{ $darkMode: boolean }>`
      color: ${({ $darkMode }) => ($darkMode ? "#A5A6A6" : "#000")};
    `}
`;

const FooterSectionIcon = styled.span<{ $darkMode: boolean }>`
  transform: rotate(-90deg);
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ $darkMode }) => ($darkMode ? "#A5A6A6" : "#52525b")};
  svg {
    width: 16px;
    height: 16px;
  }
`;

const FooterSection = ({ section, darkMode }) => {
  return (
    <FooterSectionWrapper>
      <FooterSectionTitle $darkMode={darkMode}>{section.label}</FooterSectionTitle>
      <FooterSectionList>
        {section.listItems?.map((item, i) => <FooterSectionObject item={item} key={i} darkMode={darkMode} />)}
      </FooterSectionList>
    </FooterSectionWrapper>
  );
};

const FooterSectionObject = ({ item, darkMode }) => {
  return (
    <FooterSectionItem>
      <FooterSectionLink
        $accented={item.accentedItem}
        href={parseStoryblokLink(item.link)}
        target={item.link.target}
        $darkMode={darkMode}>
        {item.label}
      </FooterSectionLink>
      {item.accentedItem && (
        <FooterSectionIcon $darkMode={darkMode}>
          <ChevronDown />
        </FooterSectionIcon>
      )}
    </FooterSectionItem>
  );
};

export default FooterSection;

import { StoryblokComponent, useStoryblokState } from "@storyblok/react";
import { ISbStoryData } from "storyblok-js-client/dist/types/interfaces";
import { ThemeProvider } from "styled-components";

import Layout from "@/layouts/CoreLayout";
import { fetchStaticPaths } from "@/lib/fetchStaticPaths";
import { fetchPageStaticProps } from "@/lib/fetchStaticProps";
import relations from "@/lib/storyblok/relations";
import { useRouter } from "next/router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { themeProvider, ThemeOption } from "@/utils/ThemeProvider";
import VideoLibraryLayout from "@/layouts/VideoLibraryLayout";

export default function Page({
  story,
  navData,
  siteSettings,
  footerData,
  resourceTypes,
  videoLibrary,
}: {
  story: ISbStoryData;
  navData: any;
  siteSettings: any;
  footerData: any;
  resourceTypes: any;
  videoLibrary: any;
}) {
  const updatedStory: ISbStoryData | null = useStoryblokState(story, {
    resolveRelations: relations,
  });

  const theme = themeProvider(updatedStory?.content?.theme as ThemeOption);

  if (updatedStory.content.component === "videoLibraryEntry") {
    return (
      <VideoLibraryLayout
        pageTitle={updatedStory?.content?.ogTitle ? updatedStory?.content?.ogTitle : `${updatedStory?.name}`}
        pageDescription={
          updatedStory?.content?.ogDescription
            ? updatedStory?.content?.ogDescription
            : updatedStory?.content?.description
        }
        pageImage={updatedStory?.content?.ogImage?.filename}
        navData={navData}
        overlappingMenu={updatedStory?.content?.overlappingMenu}
        blok={updatedStory?.content}
        theme={updatedStory?.content?.theme}
        footerData={footerData}
        siteSettings={siteSettings}
        videoLibrary={videoLibrary}>
        <ReactQueryDevtools initialIsOpen={false} />

        <StoryblokComponent blok={{ ...updatedStory?.content }} uuid={updatedStory?.uuid} />
      </VideoLibraryLayout>
    );
  }

  return (
    <Layout
      pageTitle={updatedStory?.content?.ogTitle ? updatedStory?.content?.ogTitle : `${updatedStory?.name}`}
      pageDescription={
        updatedStory?.content?.ogDescription ? updatedStory?.content?.ogDescription : updatedStory?.content?.description
      }
      pageImage={updatedStory?.content?.ogImage?.filename}
      navData={navData}
      overlappingMenu={updatedStory?.content?.overlappingMenu}
      blok={updatedStory?.content}
      theme={updatedStory?.content?.theme}
      footerData={footerData}
      siteSettings={siteSettings}>
      <ReactQueryDevtools initialIsOpen={false} />

      <StoryblokComponent blok={{ resourceTypes: resourceTypes, ...updatedStory?.content }} uuid={updatedStory?.uuid} />
    </Layout>
  );
}

export async function getStaticProps({ params, preview }) {
  const data = await fetchPageStaticProps({ params, preview });
  return {
    ...data,
  };
}

export async function getStaticPaths({ context }) {
  const data = await fetchStaticPaths(context);
  return {
    ...data,
  };
}

import NextLazyImage from "@/components/Core/NextLazyImage";
import IconRenderer from "@/components/Shared/IconRenderer";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import { motion } from "motion/react";
import Link from "next/link";
import styled, { css } from "styled-components";

export const DropdownCollectionItemGroupWrapper = styled(motion.div)`
  width: 320px;
`;

export const DropdownCollectionItemGroupLabel = styled.div<{ $darkMode?: boolean }>`
  color: var(--PP-Text-Secondary);
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  margin-bottom: 16px;
  padding-left: 12px;
  ${(props) =>
    props.$darkMode &&
    css<{ $darkMode: boolean }>`
      color: #62666e;
    `}
`;

export const DropdownCollectionItemGroupItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

const DropdownCollectionItemWrapper = styled.div<{ $comingSoon?: boolean; $darkMode?: boolean }>`
  display: flex;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  padding: 10px 12px;

  border-radius: 4px;
  transition: 0.1s;
  &:hover {
    background-color: #f3f4f6;
    transition: 0.1s;
  }
  ${(props) =>
    props.$darkMode &&
    css<{ $darkMode: boolean }>`
      &:hover {
        background-color: #222324;
        transition: 0.1s;
      }
    `}
  ${(props) =>
    props.$comingSoon &&
    css`
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    `}
`;

const DropdownCollectionIcon = styled.div<{ $comingSoon?: boolean; $darkMode?: boolean }>`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  ${(props) =>
    props.$comingSoon &&
    css`
      background-color: #f2f3f4;
      svg {
        opacity: 0.5;
      }
    `}
  ${(props) =>
    props.$darkMode &&
    css<{ $darkMode: boolean; $comingSoon?: boolean }>`
      ${(props) =>
        props.$comingSoon &&
        css`
          background-color: #131818;
          svg {
            opacity: 0.5;
          }
        `}

      border: 1px solid #232425;
    `}
`;

const DropdownCollectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const DropdownCollectionItemLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

const DropdownCollectionItemLabel = styled.div<{ $comingSoon?: boolean; $darkMode?: boolean }>`
  color: #171717;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  padding-top: 2px;
  ${(props) =>
    props.$comingSoon &&
    css`
      color: #666;
    `}
  ${(props) =>
    props.$darkMode &&
    css<{ $darkMode: boolean }>`
      color: #fff;
    `}
`;

const DropdownCollectionItemComingSoonBadge = styled.div<{ $darkMode?: boolean }>`
  background-color: var(--PrimaryAccent);
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 9px;
  line-height: 100%;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-family: var(--font-inter);
  ${(props) =>
    props.$darkMode &&
    css<{ $darkMode: boolean }>`
      background-color: #131818;
      color: #a5a6a6;
    `}
`;

const DropdownCollectionItemDescription = styled.div<{ $comingSoon?: boolean; $darkMode?: boolean }>`
  color: #666;
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-wrap: pretty;
  ${(props) =>
    props.$comingSoon &&
    css`
      color: #979696;
    `}
  ${(props) =>
    props.$darkMode &&
    css<{ $darkMode: boolean }>`
      color: #8b8f99;
    `}
`;

const wrapperAnim = {
  hidden: {
    opacity: 0,
    y: 5,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export default function DropdownCollectionItemGroup({
  label,
  items,
  isOpen,
  setIsOpen,
  darkMode,
}: {
  label: string;
  items: Array<{
    label: string;
    description: string;
    icon: string;
    raw_link?: string;
    comingSoonMode?: boolean;
  }>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  darkMode?: boolean;
}) {
  return (
    <DropdownCollectionItemGroupWrapper
      animate={isOpen ? "visible" : "hidden"}
      initial={"hidden"}
      variants={wrapperAnim}>
      <DropdownCollectionItemGroupLabel $darkMode={darkMode}>{label}</DropdownCollectionItemGroupLabel>
      <DropdownCollectionItemGroupItems>
        {items?.map((item, i) => (
          <DropdownCollectionItem key={i} item={item} setIsOpen={setIsOpen} darkMode={darkMode} />
        ))}
      </DropdownCollectionItemGroupItems>
    </DropdownCollectionItemGroupWrapper>
  );
}

export const DropdownCollectionItem = ({ item, setIsOpen, darkMode }) => {
  const handleClick = () => {
    setIsOpen(false);
  };

  return (
    <LinkWrapper item={item} handleClick={handleClick}>
      <DropdownCollectionItemWrapper $comingSoon={item.comingSoonMode} $darkMode={darkMode}>
        <DropdownCollectionIcon $comingSoon={item.comingSoonMode} $darkMode={darkMode}>
          <IconRenderer icon={item.icon} size={18} color={darkMode ? "#A5A6A6" : "#52525b"} />
        </DropdownCollectionIcon>
        <DropdownCollectionContentWrapper>
          <DropdownCollectionItemLabelWrapper>
            <DropdownCollectionItemLabel $comingSoon={item.comingSoonMode} $darkMode={darkMode}>
              {item.label}
            </DropdownCollectionItemLabel>
            {item.comingSoonMode && (
              <DropdownCollectionItemComingSoonBadge $darkMode={darkMode}>
                Coming Soon
              </DropdownCollectionItemComingSoonBadge>
            )}
          </DropdownCollectionItemLabelWrapper>
          <DropdownCollectionItemDescription $comingSoon={item.comingSoonMode} $darkMode={darkMode}>
            {item.description}
          </DropdownCollectionItemDescription>
        </DropdownCollectionContentWrapper>
      </DropdownCollectionItemWrapper>
    </LinkWrapper>
  );
};

const LinkWrapper = ({ item, handleClick, children }) => {
  if (item.comingSoonMode) {
    return <div>{children}</div>;
  }
  return (
    <Link
      href={item.raw_link ? item.raw_link : parseStoryblokLink(item.link)}
      onClick={handleClick}
      target={item.raw_link ? null : item.link.target}
      style={{ textDecoration: "none" }}>
      {children}
    </Link>
  );
};
